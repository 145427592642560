import React, { Component } from 'react';
import { RetrieveOrderInformation } from '../services/WovarInformationService';
import moment from 'moment';

class Home extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            order : null
        };
    }

    submit(event) {
        event.preventDefault();

        let frm = event.target.form;
        let self = this;
        self.setState({ order : null });

        RetrieveOrderInformation(frm.elements.sherpaanOrderId.value).then(d => self.setState({ order : d }));
    }

    render() {
        let order = this.state.order;
        return (<div className="container">
            <div className="panel">
                <h3>Wovar orderinformation</h3>

                <div className="content">
                    <form className="cols">                            
                        <div className="formgroup">
                            <label>Sherpaan orderId</label>
                            <input className="extraWide" name="sherpaanOrderId" />
                        </div>

                        <button onClick={(event) => this.submit(event)}>Ophalen</button>
                    </form>
                </div>
                <hr />
                {!!order ? 
                <div className="content">
                    <dl className="baseInformation">
                        <dt>Gadero ref.</dt>
                        <dd>{order.reference}</dd>

                        <dt>Status</dt>
                        <dd>{order.status}</dd>

                        <dt>Afleveradres.</dt>
                        <dd className="brisenter">{order.clientAddress}</dd>

                        <dt>Waarschuwing</dt>
                        <dd>{order.orderWarning}</dd>          

                        <dt>Gevraagde verzending:</dt>
                        <dd>{moment(order.requestShippingDate).format( "LLL")}</dd>
                    </dl>
                    <hr />
                    <ul>
                        <li>
                            <span className="itemcode">ItemCode</span>
                            <span className="description">Description</span>
                            <span className="quantityOrdered">QuantityOrdered</span>
                            <span className="quantityBackorder">QuantityBackorder</span>
                        </li>
                        {order.orderLines.map((ol, i) => <li key={i}>
                            <span className="itemcode">{ol.itemCode}</span>
                            <span className="description">{ol.description}</span>
                            <span className="quantityOrdered">{ol.quantityOrdered}</span>
                            <span className="quantityBackorder">{ol.quantityBackorder}</span>
                        </li>)}
                    </ul>
                    <hr />
                    Pakketten:
                    <ul>
                        {order.parcels.map((ol, i) => <li key={i}>
                            <span className="parcelServiceCode">{ol.parcelServiceCode}</span>
                            <span className="barcode">{ol.barcode}</span>

                            <a target="_blank" rel="noopener noreferrer" href={ol.trackTraceUrl} className="trackTraceUrl">{ol.trackTraceUrl}</a>
                            <span className="parcelSentDate">{moment(ol.parcelSentDate).format( "LLL")}</span>
                        </li>)}
                    </ul>
                </div> : null
                }
            </div>
        </div>);
    }
}

export default Home;