import React, { Component } from 'react';

class App extends Component {

    render() {
        return (
            <React.Fragment>
                <header>
                    <a href="https://gadero.nl"><img alt="logo" src={process.env.PUBLIC_URL + '/img/logo_gadero_wit.png'} className="no-print" /></a>
                    <img alt="logo" src={process.env.PUBLIC_URL + '/img/logo_gadero_print.png'} className="print-only" />    
                    {this.props.title || 'Wovar orderinformatie' }                
                </header>

                <main>
                    {this.props.children}
                </main>

                <footer>
                    <div className="container">
                        <div className="sloganColumn">
                            <h4>Al uw hout snel thuis met gadero</h4>
                            <p>
                                Gadero levert verantwoord tuinhout en bezorgt gemiddeld binnen 4 werkdagen bij u thuis in Nederland en België
                            </p>
                            <p className="logobar">
                                <img alt="fsc"                  src={process.env.PUBLIC_URL + '/img/fsc.png'} />
                                <img alt="pefc"                 src={process.env.PUBLIC_URL + '/img/pefc.png'} />
                                <img alt="komo"                 src={process.env.PUBLIC_URL + '/img/komo.png'} />
                                <img alt="thuiswinkel waarborg" src={process.env.PUBLIC_URL + '/img/thuiswinkel.png'} />
                            </p>
                        </div>

                        <div className="contactColumn">
                            <span className="subtitle">Verbetertips? <strong>Deel ze met ons!</strong></span>
                            <p>
                                <span className="subtitle">Komt u er niet uit? We helpen u graag:</span>
                                <span className="telephone"><a href="tel:+31502306151">+31 (0) 50 - 230.61.51 (NL)</a></span>
                                <span className="telephone"><a href="tel:+3238081789">+32 (0) 3 - 808.1789 (B)</a></span>
                            </p>
                            <p>
                                Euvelgunnerweg 19<br />
                                9723 CT Groningen
                            </p>
                        </div>   
                    </div>         
                </footer>
            </React.Fragment>
        );
    }

}

export default App;
